import React, {useState, useEffect} from 'react';
import "./style.styl";

export default function LandingAndPromoSitesPage(props) {
    return (
        <div className="container draft-view">
           <h1>Landings and Promo sites</h1>
           <p className="text-muted">It seems simple but the creation of the right landing page is a complex task that requires specific experience. Therefore, having a great landing page lets you focus on your target audience and truly offer them value. So, landing pages generate more leads, than standard web pages.</p>
           <p className="text-muted">Wescale web developers and digital marketers have specific experience and they will create the right landing page for you.</p>
           <p className="text-muted"><br /></p>
           <h2>How a landing page helps your business</h2>
           <p className="text-muted">In digital marketing, the landing page is part of a digital strategy designed with a single focused objective is to convert site visitors into sales or leads.</p>
           <p className="text-muted">As part of digital strategy landing pages are often linked to social media, email campaigns, or search engine marketing campaigns in order to enhance the effectiveness of the advertisements.</p>
           <p className="text-muted">Usually landing pages have a higher conversion rate than standard web pages such as the home page or internal pages on your site.&nbsp;<span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">All elements of landing pages work to generate leads.</span></p>
           <p className="text-muted">The landing page development is enough simple process with minimum requirements. That is why some businessmen make a common mistake. They use web builders or to hire a beginner. Often this approach leads to poor results for many cases.</p>
           <p className="text-muted">To avoid failure we recommend hire digital marketing and web development teams with strong skills.</p>
           <p className="text-muted">Marketers will find how to highlight the value of your offerings. Web developers will create mobile-friendly landing pages without bugs.</p>
           <p className="text-muted"><br /></p>
           <h2>A landing page development process</h2>
           <p className="text-muted">The development of the high converting landing page can take several days or a month. It all depends on the complexity of the project, the design, as well as the presence of additional elements, such as animations.</p>
           <p className="text-muted"></p>
           <h5>Wescale landing page development services include:</h5>
           <ul>
              <li className="text-muted">A deep analysis of the business-niche and competitors.</li>
              <li className="text-muted">A unique text designed to reflect the portrait of the target audience.</li>
              <li className="text-muted">An SEO-optimized page, ready for further promotion, PPC campaigns.</li>
              <li className="text-muted">A comfortable CMS that can be easily managed (WordPress development).</li>
              <li className="text-muted">A connected analytics system for collecting data and analyzing the effectiveness of the landing page</li>
              <li className="text-muted">An adaptive, mobile-friendly layout.</li>
              <li className="text-muted">Modern UI design.</li>
           </ul>
           <h5>When creating a landing page we emphasize on:</h5>
           <ul>
              <li className="text-muted">Landing page design</li>
              <li className="text-muted">The сontent: attractive commercial texts, photos, and videos.</li>
              <li className="text-muted">Social proofs</li>
              <li className="text-muted">Call to action.</li>
              <li className="text-muted">Functional landing page: order form or feedback, online consultation.</li>
           </ul>
           <p className="text-muted">Ideally, the landing page should be created based on an existing design of a corporate site, business card site, or online store.</p>
           <p className="text-muted"><br /></p>
           <h2>The main elements of a typical landing page:</h2>
           <ol>
              <li className="text-muted">Clear, concise action-oriented headlines</li>
              <li className="text-muted">A good explanation of the product or service offered</li>
              <li className="text-muted">A value proposition</li>
              <li className="text-muted">A forceful call to action</li>
              <li className="text-muted">A simple color scheme with contrasting colors</li>
           </ol>
           <p className="text-muted"><br /></p>
           <h2>What problems does the creation of a landing page solve?</h2>
           <p className="text-muted"><br/></p>
           <h5>1. Sale of goods</h5>
           <p className="text-muted">The landing page works great on sales. It will help to focus on a particular product, draw attention to a seasonal or exclusive product, or get rid of storage deposits.</p>
           <p className="text-muted"></p>
           <h5>2. Selling services</h5>
           <p  className="text-muted">Do you offer hardware manicure or Thai massage, Landing Page Creation on WordPress, or Website Development? The range of services your salon or agency replenished with an interesting novelty? – A well-built landing page will help to share this with potential clients</p>
           <p className="text-muted"></p>
           <h5>3. Base collection of subscribers</h5>
           <p className="text-muted">To build an e-mail strategy and quickly reach subscribers any time there is a promotion Engage your audience with useful thematic content – and plan a chain of letters!</p>
           <p className="text-muted"></p>
           <h5>4. New Product Announcement</h5>
           <p  className="text-muted">Develop a landing page if you have a new product and want to present it correctly to your target audience. Landing pages are a great way to advertise your product and tell about its advantages and features.</p>
           <p className="text-muted"></p>
           <h5>5. Event Advertising</h5>
           <p className="text-muted">The concert, master class, workshop, online marathon – any event can be promoted using the landing page. So you quickly collect the required number of participants and justify the organizational costs.</p>
           <p className="text-muted"></p>
           <h5>6. Startup promotion</h5>
           <p  className="text-muted">Starting a business does not always have the opportunity or need to create a full-fledged website for a startup. While a startup is at the stage of promotion, a Landing page is enough for him to attract leads and analyze demand.</p>
           <p className="text-muted"><br/></p>
           <h2 >How to get the maximum result</h2>
           <p  className="text-muted">It is important to remember that creating a one-page site does not in itself work for conversion. To quickly attract targeted visitors to the site, you need to connect additional marketing tools:</p>
           <p className="text-muted"><br/></p>
           <h5 >Setting contextual advertising</h5>
           <p  className="text-muted">Choosing the right key requests and platforms for displaying advertisements. With this tool, you will quickly collect customer data from search engines.</p>
           <p  className="text-muted"><br /></p>
           <h5>Targeted advertising in social networks</h5>
           <p  className="text-muted">The optimal budget, fine-tuning the choice of the target audience will accelerate the attraction of visitors to the landing page from Facebook and Instagram, and other social media platforms.</p>
           <p  className="text-muted"><br /></p>
           <h5>Email marketing</h5>
           <p  className="text-muted">If you have a subscriber base, you can use E-mail marketing to increase traffic to the landing page. The main thing is to correctly build a sales funnel and use sales texts, taking into account the type of audience you are dealing with.</p>
           <p  className="text-muted"><br /></p>
           <h2 >Conclusion</h2><p  className="text-muted">Remember that landing pages cannot fully compete in organic delivery with large websites. Therefore, traditional SEO methods in landing pages do not work 100%.</p>
           <p  className="text-muted">In addition, to get the most out of a landing page, the most important factor is the constant collection and analysis of statistics. Based on the obtained results, we can draw the right conclusions about the strategy for further development of the one-page site and the advertised product or service. And also, make the necessary changes promptly.</p>
           <p  className="text-muted">In a word, the landing page development process is quite complicated and time-consuming. If some background in web development and the will to spend time on your project, you can develop your own landing page. Otherwise, consider entrusting this task to professionals and we would love to assist you here at Wescale.</p>

        </div>


    );
}
